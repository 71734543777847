import { Navbar, Container, Nav } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import About from './components/about';
import Home from './components/home';
import Blog from './components/blog';
import Contact from './components/contact';
import Programs from './components/programs';
import './App.scss';

function App() {
  return (
    <Router>
      <div className="App" style={{ height: '100vh', width: '100%' }}>
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand><Link className='menu-link' to="/">Medina Boxing</Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Item className="nav-item"><Link className='menu-link' to="/about">About</Link></Nav.Item>
                <Nav.Item><Link className='menu-link' to="/blog">Blog</Link></Nav.Item>
                <Nav.Item><Link className='menu-link' to="/programs">Programs</Link></Nav.Item>
                <Nav.Item><Link className='menu-link' to="/contact">Contact</Link></Nav.Item>
              </Nav>
            </Navbar.Collapse>

          </Container>
        </Navbar>
        <Routes>
          <Route exact path="/" element={ <Home></Home> }></Route>
          <Route exact path="/blog" element={ <Blog></Blog> }></Route>
          <Route exact path="/about" element={ <About></About> }></Route>
          <Route exact path="/programs" element={ <Programs></Programs> }></Route>
          <Route exact path="/contact" element={ <Contact></Contact> }></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
